import { ShaderGradient } from "./core";

export const createBlackWhiteColorGradient = (color4: number[]): ShaderGradient => ({
  stops: [
    { t: 0.00, color: [1, 1, 1, 1] },
    { t: 0.15, color: color4 },
    { t: 0.50, color: [0, 0, 0, 1] },
    { t: 0.85, color: color4 },
    { t: 1.00, color: [1, 1, 1, 1] },
  ]
});

export const createTwoColorFade = (fromColor4: number[], toColor4: number[]): ShaderGradient => ({
  stops: [
    { t: 0.00, color: fromColor4 },
    { t: 1.00, color: toColor4 },
  ]
});

export const createFadeOut = (color4: number[]): ShaderGradient => 
  createTwoColorFade(color4, [...color4.slice(0, 3), 0]);

export const createFadeIn = (color4: number[]): ShaderGradient => 
  createTwoColorFade([...color4.slice(0, 3), 0], color4);

export const rainbow: ShaderGradient = {
  stops: [
    { t: 0 / 6, color: [1, 0, 0, 1] },
    { t: 1 / 6, color: [1, 1, 0, 1] },
    { t: 2 / 6, color: [0, 1, 0, 1] },
    { t: 3 / 6, color: [0, 1, 1, 1] },
    { t: 4 / 6, color: [0, 0, 1, 1] },
    { t: 5 / 6, color: [1, 0, 1, 1] },
    { t: 6 / 6, color: [1, 0, 0, 1] },
  ]
};

export const pastelRainbow: ShaderGradient = {
  stops: [
    { t: 0 / 6, color: [1.0, 0.5, 0.5, 1.0] },
    { t: 1 / 6, color: [1.0, 1.0, 0.5, 1.0] },
    { t: 2 / 6, color: [0.5, 1.0, 0.5, 1.0] },
    { t: 3 / 6, color: [0.5, 1.0, 1.0, 1.0] },
    { t: 4 / 6, color: [0.5, 0.5, 1.0, 1.0] },
    { t: 5 / 6, color: [1.0, 0.5, 1.0, 1.0] },
    { t: 6 / 6, color: [1.0, 0.5, 0.5, 1.0] },
  ]
};

export const summer: ShaderGradient = { "stops": [{ "t": 0, "color": [1, 1, 1, 1] }, { "t": 1, "color": [1, 1, 1, 1] }, { "t": 0.5, "color": [0.10196078431372549, 0, 0.7254901960784313, 1] }, { "t": 0.14395821389897445, "color": [0, 0.9372549019607843, 1, 1] }, { "t": 0.702465125167208, "color": [1, 0, 0, 1] }, { "t": 0.853302758137461, "color": [1, 0.8431372549019608, 0, 1] }] };
export const raverie: ShaderGradient = { "stops": [{ "t": 0, "color": [1, 0.984313725490196, 0.3843137254901961, 1] }, { "t": 0.23358175679979618, "color": [1, 1, 1, 1] }, { "t": 0.5081533855659596, "color": [0.5882352941176471, 0.19215686274509805, 0.8862745098039215, 1] }, { "t": 0.7799222880438245, "color": [0.3254901960784314, 1, 0.7176470588235294, 1] }, { "t": 1, "color": [1, 0.984313725490196, 0.3843137254901961, 1] }, { "t": 0.429326708707561, "color": [0.9725490196078431, 0.5764705882352941, 1, 1] }, { "t": 0.5720109561118543, "color": [0.5294117647058824, 0.19215686274509805, 0.8862745098039215, 1] }, { "t": 0.2866424613032677, "color": [1, 1, 1, 1] }] };
export const gold: ShaderGradient = {"stops":[{"t":0,"color":[0.7019607843137254,0.5372549019607843,0,1]},{"t":1,"color":[0.7019607843137254,0.5372549019607843,0,1]},{"t":0.5,"color":[0.8470588235294118,0.6666666666666666,0,1]},{"t":0.23364545512453022,"color":[1,0.9490196078431372,0.4,1]},{"t":0.7513854385629658,"color":[1,0.9647058823529412,0.7490196078431373,1]}]};
export const purpleGold: ShaderGradient = {"stops":[{"t":0,"color":[0.9607843137254902,0,0.4980392156862745,1]},{"t":0.9714631505191413,"color":[0.7019607843137254,0.5372549019607843,0,1]},{"t":0.5,"color":[0.8862745098039215,0.7294117647058823,0.1411764705882353,1]},{"t":0.20918529842665137,"color":[0.4588235294117647,0.12156862745098039,0.5568627450980392,1]},{"t":0.7513854385629658,"color":[1,0.9921568627450981,0.9019607843137255,1]},{"t":1,"color":[0.9607843137254902,0,0.4980392156862745,1]}]};
export const purpleBlue: ShaderGradient = {"stops":[{"t":0,"color":[0.9607843137254902,0,0.4980392156862745,1]},{"t":0.8654691381616664,"color":[0.027450980392156862,0,1,1]},{"t":0.4633097649531817,"color":[0,1,0.8313725490196079,1]},{"t":0.20918529842665137,"color":[0.4588235294117647,0.12156862745098039,0.5568627450980392,1]},{"t":0.7187718962991273,"color":[0.1568627450980392,0.30980392156862746,0.7058823529411765,1]},{"t":1,"color":[0.9607843137254902,0,0.4980392156862745,1]}]};
export const greenYellow: ShaderGradient = {"stops":[{"t":0,"color":[0.21568627450980393,0.35294117647058826,0.42745098039215684,1]},{"t":0.5026434804764635,"color":[0.9137254901960784,0.8901960784313725,0.4745098039215686,1]},{"t":0.7242181030638895,"color":[0,1,0.8313725490196079,1]},{"t":0.144005987642525,"color":[0.1450980392156863,0.5529411764705883,0.596078431372549,1]},{"t":0.3355627746990254,"color":[0.6196078431372549,0.8392156862745098,0.396078431372549,1]},{"t":1,"color":[0.21568627450980393,0.35294117647058826,0.42745098039215684,1]}]};
export const heatVision: ShaderGradient = {"stops":[{"t":0,"color":[0,0,0,1]},{"t":0.36811261863812983,"color":[0.06274509803921569,0.7215686274509804,0.8980392156862745,1]},{"t":0.4877699216510606,"color":[0.4549019607843137,0.8,0.8470588235294118,1]},{"t":0.12362252372762596,"color":[0.050980392156862744,0.13333333333333333,0.3411764705882353,1]},{"t":0.24995222625644944,"color":[0.043137254901960784,0.4117647058823529,0.788235294117647,1]},{"t":0.8954184463626293,"color":[0.9333333333333333,0.28627450980392155,0.16862745098039217,1]},{"t":0.6739282756863495,"color":[0.4392156862745098,0.7450980392156863,0.3568627450980392,1]},{"t":0.5883177272437735,"color":[0,0.36470588235294116,0.6784313725490196,1]},{"t":0.8003057519587234,"color":[0.9450980392156862,0.9411764705882353,0.27450980392156865,1]},{"t":0.9628756092392456,"color":[1,0,0,1]},{"t":1,"color":[0,0,0,1]}]};
export const blood: ShaderGradient = {"stops":[{"t":0,"color":[0.35294117647058826,0,0,1]},{"t":1,"color":[0.35294117647058826,0,0,1]},{"t":0.34508567424676734,"color":[0.7450980392156863,0,0,1]},{"t":0.5516274921969552,"color":[0.18823529411764706,0,0,1]},{"t":0.767692209694885,"color":[1,0.17647058823529413,0.17647058823529413,1]}]};
export const lime: ShaderGradient = {"stops":[{"t":0,"color":[0.00784313725490196,0.2784313725490196,0.2980392156862745,1]},{"t":0.3472183420837146,"color":[0.6431372549019608,0.7647058823529411,0.0196078431372549,1]},{"t":0.499180773455489,"color":[0.9019607843137255,0.8431372549019608,0.00784313725490196,1]},{"t":0.1840811285316922,"color":[0.027450980392156862,0.5098039215686274,0.28627450980392155,1]},{"t":0.634584979795119,"color":[0.996078431372549,0.7725490196078432,0.00784313725490196,1]},{"t":1,"color":[0.00784313725490196,0.2784313725490196,0.2980392156862745,1]},{"t":0.8274167910986947,"color":[0.6431372549019608,0.7647058823529411,0.0196078431372549,1]}]};
export const flowerPinkBlue: ShaderGradient = {"stops":[{"t":0,"color":[0,0.1803921568627451,0.5647058823529412,1]},{"t":1,"color":[0,0.1803921568627451,0.5647058823529412,1]},{"t":0.11157940368961028,"color":[0.2901960784313726,0.29411764705882354,0.788235294117647,1]},{"t":0.24478589663311012,"color":[0.611764705882353,0.38823529411764707,0.8470588235294118,1]},{"t":0.3588459130099706,"color":[0.8509803921568627,0.3411764705882353,0.7333333333333333,1]},{"t":0.4975675545493955,"color":[0.8627450980392157,0.2549019607843137,0.615686274509804,1]},{"t":0.611627570926256,"color":[0.8,0.10980392156862745,0.44313725490196076,1]},{"t":0.7534319156110014,"color":[0.7333333333333333,0.00784313725490196,0.27058823529411763,1]}]};
export const aqua: ShaderGradient = {"stops":[{"t":0,"color":[0,0.5411764705882353,0.4,1]},{"t":0.6917296854679447,"color":[0.23529411764705882,0.3215686274509804,0.5176470588235295,1]},{"t":0.31812051442608735,"color":[0.33725490196078434,0.8627450980392157,0.8980392156862745,1]},{"t":0.17388372429073745,"color":[0.17647058823529413,0.8627450980392157,0.7019607843137254,1]},{"t":0.4513270073695872,"color":[0.00392156862745098,0.7294117647058823,0.8392156862745098,1]},{"t":0.5808005394730504,"color":[0.00392156862745098,0.45098039215686275,0.6549019607843137,1]},{"t":1,"color":[0,0.5411764705882353,0.4,1]},{"t":0.8428303068252975,"color":[0,0.34509803921568627,0.3411764705882353,1]}]};
export const purpleFlame: ShaderGradient = {"stops":[{"t":0,"color":[1,0.6862745098039216,0,1]},{"t":0.6886469823226241,"color":[0.34901960784313724,0.2196078431372549,0.6705882352941176,1]},{"t":0.26571456095563795,"color":[0.8862745098039215,0.09803921568627451,0.4666666666666667,1]},{"t":0.12237929188173058,"color":[0.984313725490196,0.39215686274509803,0.1843137254901961,1]},{"t":0.4157737326523966,"color":[0.49411764705882355,0.08235294117647059,0.48627450980392156,1]},{"t":0.5327016310241235,"color":[0.37254901960784315,0.11764705882352941,0.48627450980392156,1]},{"t":1,"color":[1,0.6862745098039216,0,1]},{"t":0.8613265256972208,"color":[0.7411764705882353,0.11764705882352941,0.3568627450980392,1]}]};
export const bluePurple: ShaderGradient = {"stops":[{"t":0,"color":[0.00392156862745098,0.6078431372549019,0.7411764705882353,1]},{"t":0.799624295554164,"color":[0.8784313725490196,0.13333333333333333,0.5490196078431373,1]},{"t":0.3212032175714079,"color":[0.08235294117647059,0.08627450980392157,0.34901960784313724,1]},{"t":0.16861983906153888,"color":[0.011764705882352941,0.40784313725490196,0.7215686274509804,1]},{"t":0.5020894207213721,"color":[0.2549019607843137,0.10196078431372549,0.2784313725490196,1]},{"t":0.6714232725635484,"color":[0.4627450980392157,0.13725490196078433,0.4588235294117647,1]},{"t":1,"color":[0.00392156862745098,0.6078431372549019,0.7411764705882353,1]}]};
export const blackAndWhiteMetallic: ShaderGradient = { "stops": [{ "t": 0, "color": [0, 0, 0, 1] }, { "t": 0.23358175679979618, "color": [1, 1, 1, 1] }, { "t": 0.646760940187273, "color": [0.23921568627450981, 0.23921568627450981, 0.23921568627450981, 1] }, { "t": 0.747308745779986, "color": [0, 0, 0, 1] }, { "t": 1, "color": [0, 0, 0, 1] }, { "t": 0.429326708707561, "color": [0.792156862745098, 0.792156862745098, 0.792156862745098, 1] }, { "t": 0.8492260653544812, "color": [1, 1, 1, 1] }, { "t": 0.2866424613032677, "color": [0.40784313725490196, 0.40784313725490196, 0.40784313725490196, 1] }] };

export const commonSolidGradients: ShaderGradient[] = [
  rainbow,
  pastelRainbow,
  summer,
  raverie,
  gold,
  purpleGold,
  purpleBlue,
  greenYellow,
  heatVision,
  blood,
  lime, 
  flowerPinkBlue,
  aqua,
  purpleFlame,
  bluePurple,
  blackAndWhiteMetallic,
  createBlackWhiteColorGradient([1, 0, 0, 1]),
  createBlackWhiteColorGradient([1, 1, 0, 1]),
  createBlackWhiteColorGradient([0, 1, 0, 1]),
  createBlackWhiteColorGradient([0, 1, 1, 1]),
  createBlackWhiteColorGradient([0, 0, 1, 1]),
  createBlackWhiteColorGradient([1, 0, 1, 1]),
];

export const commonAlphaGradients: ShaderGradient[] = [
  createFadeOut([1, 1, 1, 1]),
  createFadeOut([0, 0, 0, 1]),
  createFadeIn([1, 1, 1, 1]),
  createFadeIn([0, 0, 0, 1]),
];

export const commonGradients: ShaderGradient[] = [
  ...commonSolidGradients,
  ...commonAlphaGradients
];

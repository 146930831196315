import { LayerShader } from "../core";

export const godRaysLayer: LayerShader = {
  type: "shader",
  name: "god rays",
  id: "god rays",
  visible: true,
  code: `
uniform vec2 position; // type: "position"
uniform float rayLength; // default: 0.5, min: 0, max: 1
uniform float intensity; // default: 1, min: 0, max: 2
uniform gradient rayColors; // default: {0:[1, 1, 1, 1], 1:[1, 1, 1, 1]}
uniform float shimmer; // default: 1, min: 0, max: 1
uniform float shimmerSpeed; // default: 1, min: 0, max: 2
uniform float shimmerSize; // default: 1, min: 0, max: 5

// CC0 license https://creativecommons.org/share-your-work/public-domain/cc0/

/////////////// K.jpg's Simplex-like Re-oriented 4-Point BCC Noise ///////////////
//////////////////// Output: vec4(dF/dx, dF/dy, dF/dz, value) ////////////////////

// Inspired by Stefan Gustavson's noise
vec4 permute(vec4 t) {
    return t * (t * 34.0 + 133.0);
}

// Gradient set is a normalized expanded rhombic dodecahedron
vec3 grad(float hash) {
    
    // Random vertex of a cube, +/- 1 each
    vec3 cube = mod(floor(hash / vec3(1.0, 2.0, 4.0)), 2.0) * 2.0 - 1.0;
    
    // Random edge of the three edges connected to that vertex
    // Also a cuboctahedral vertex
    // And corresponds to the face of its dual, the rhombic dodecahedron
    vec3 cuboct = cube;
    cuboct[int(hash / 16.0)] = 0.0;
    
    // In a funky way, pick one of the four points on the rhombic face
    float type = mod(floor(hash / 8.0), 2.0);
    vec3 rhomb = (1.0 - type) * cube + type * (cuboct + cross(cube, cuboct));
    
    // Expand it so that the new edges are the same length
    // as the existing ones
    vec3 grad = cuboct * 1.22474487139 + rhomb;
    
    // To make all gradients the same length, we only need to shorten the
    // second type of vector. We also put in the whole noise scale constant.
    // The compiler should reduce it into the existing floats. I think.
    grad *= (1.0 - 0.042942436724648037 * type) * 32.80201376986577;
    
    return grad;
}

// BCC lattice split up into 2 cube lattices
vec4 bccNoiseBase(vec3 X) {
    
    // First half-lattice, closest edge
    vec3 v1 = round(X);
    vec3 d1 = X - v1;
    vec3 score1 = abs(d1);
    vec3 dir1 = step(max(score1.yzx, score1.zxy), score1);
    vec3 v2 = v1 + dir1 * sign(d1);
    vec3 d2 = X - v2;
    
    // Second half-lattice, closest edge
    vec3 X2 = X + 144.5;
    vec3 v3 = round(X2);
    vec3 d3 = X2 - v3;
    vec3 score2 = abs(d3);
    vec3 dir2 = step(max(score2.yzx, score2.zxy), score2);
    vec3 v4 = v3 + dir2 * sign(d3);
    vec3 d4 = X2 - v4;
    
    // Gradient hashes for the four points, two from each half-lattice
    vec4 hashes = permute(mod(vec4(v1.x, v2.x, v3.x, v4.x), 289.0));
    hashes = permute(mod(hashes + vec4(v1.y, v2.y, v3.y, v4.y), 289.0));
    hashes = mod(permute(mod(hashes + vec4(v1.z, v2.z, v3.z, v4.z), 289.0)), 48.0);
    
    // Gradient extrapolations & kernel function
    vec4 a = max(0.5 - vec4(dot(d1, d1), dot(d2, d2), dot(d3, d3), dot(d4, d4)), 0.0);
    vec4 aa = a * a; vec4 aaaa = aa * aa;
    vec3 g1 = grad(hashes.x); vec3 g2 = grad(hashes.y);
    vec3 g3 = grad(hashes.z); vec3 g4 = grad(hashes.w);
    vec4 extrapolations = vec4(dot(d1, g1), dot(d2, g2), dot(d3, g3), dot(d4, g4));
    
    // Derivatives of the noise
    vec3 derivative = -8.0 * mat4x3(d1, d2, d3, d4) * (aa * a * extrapolations)
        + mat4x3(g1, g2, g3, g4) * aaaa;
    
    // Return it all as a vec4
    return vec4(derivative, dot(aaaa, extrapolations));
}

// Use this if you don't want Z to look different from X and Y
vec4 bccNoiseClassic(vec3 X) {
    
    // Rotate around the main diagonal. Not a skew transform.
    vec4 result = bccNoiseBase(dot(X, vec3(2.0/3.0)) - X);
    return vec4(dot(result.xyz, vec3(2.0/3.0)) - result.xyz, result.w);
}

// Use this if you want to show X and Y in a plane, and use Z for time, etc.
// Z repeats on an interval of 289*sqrt(3)
vec4 bccNoisePlaneFirst(vec3 X) {
    
    // Rotate so Z points down the main diagonal. Not a skew transform.
    mat3 orthonormalMap = mat3(
        0.788675134594813, -0.211324865405187, -0.577350269189626,
        -0.211324865405187, 0.788675134594813, -0.577350269189626,
        0.577350269189626, 0.577350269189626, 0.577350269189626);
    
    vec4 result = bccNoiseBase(orthonormalMap * X);
    return vec4(result.xyz * orthonormalMap, result.w);
}

float bccNoiseWrapZ(float zValue) {
  return mod(zValue, 578.0) * 0.8660254037844386;
}

//////////////////////////////// End noise code ////////////////////////////////

const int ITERATIONS = 20;
vec4 render() {
  vec4 color = vec4(0);
  float totalNoise = 0.0;
  for (int i = 0; i < ITERATIONS; ++i) {
    float interpolant = (float(i) / float(ITERATIONS));
    float t = rayLength * interpolant;
    vec2 uv = mix(gPosition, position, t) * 0.5 + 0.5;
    vec4 noiseResult = bccNoisePlaneFirst(vec3(uv * 100.0 / max(shimmerSize, 0.01), bccNoiseWrapZ(gTime * shimmerSpeed)));
    // Normally the noise returned is from [-1, 1]
    // We would typically rescale to [0,1], however since the average should be 0.5
    // it means we get half the light as usual, so we double it to the range [0, 2]
    float shimmerNoise = mix(1.0, noiseResult.w + 1.0, shimmer);
    vec4 sampleColor = texture(gPreviousLayer, uv) * shimmerNoise;
    float noise = max(gNoise2D(uv), 0.01);
    totalNoise += noise;
    vec4 sampleGradient = gSampleGradient(rayColors, interpolant);
    color += sampleColor * noise * sampleGradient;
  }
  color *= 1.0 / totalNoise;
  color.rgb *= intensity;
  color.a = clamp(color.a, 0.0, 1.0);
  return color;
}`.trim(),
  blendMode: "normal",
  opacity: 1,
  values: []
};

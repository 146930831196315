// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cm-gutter {
  min-width: 37px;
}
.cm-diagnostic {
  background-color: #252525;
}
.cm-lintRange-error {
  background-blend-mode: color-dodge;
}
`, "",{"version":3,"sources":["webpack://./frontend/visualizerCodeEditor.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAEA;EACE,yBAAA;AAAF;AAGA;EACE,kCAAA;AADF","sourcesContent":[".cm-gutter {\n  min-width: 37px;\n}\n\n.cm-diagnostic {\n  background-color: #252525;\n}\n\n.cm-lintRange-error {\n  background-blend-mode: color-dodge;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
